.rotate-container {
  position: relative;
  width: 100px;
  height: 20px;
  perspective: 1000px;
  background: inherit;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  backface-visibility: hidden;
  transition: transform 0.8s;
  background: white;
}

.current-card {
  transform: rotateX(0deg);
}

.next-card {
  transform: rotateX(180deg);
  z-index: -1;
}

.rotate-out {
  transform: rotateX(0deg);
}

.rotate-in {
  transform: rotateX(0deg);
  z-index: 1;
}
