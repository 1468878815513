.Toastify__toast-container {
  min-width: 382px;
}
.Toastify__toast {
  padding: 0;
  margin: 4;
  min-width: 382px;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
  min-width: 382px;
}
