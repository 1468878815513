:root {
  --rmdp-primary-gray: #707070;
  --rmdp-secondary-gray: #e8eefd;
  --rmdp-shadow-gray: #dedfe2;
  --rmdp-today-gray: rgba(255, 255, 255, 0.4);
  --rmdp-hover-gray: #f7f9fd;
  --rmdp-deselect-gray: #00000000;
  --rmdp-transparent-gray: transparent;
  --rmdp-black: black;
  --rmdp-range: #f7f9fd;
  --rmdp-range-start-end: #e8eefd;
}

.gray .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-gray);
  box-shadow: 0 0 5px var(--rmdp-secondary-gray);
}

.gray .rmdp-panel-body li {
  background-color: var(--rmdp-primary-gray);
  box-shadow: 0 0 2px var(--rmdp-secondary-gray);
}

.gray .rmdp-week-day {
  color: var(--rmdp-primary-gray);
}

.gray .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-gray);
}

.gray .rmdp-range.start,
.gray .rmdp-range.end {
  background-color: var(--rmdp-range);
}

.gray .rmdp-range {
  background-color: var(--rmdp-range);
  color: var(--rmdp-black);
  box-shadow: none;
}

.gray .rmdp-day,
.gray .rmdp-week-day {
  width: 42px;
  height: 42px;
}

.gray .rmdp-day span {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.rmdp-range.start span,
.rmdp-range.end span {
  background: var(--rmdp-range-start-end);
}

.gray .rmdp-arrow {
  border: solid var(--rmdp-primary-gray);
  border-width: 0 2px 2px 0;
}

.gray .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-gray);
  box-shadow: 0 0 3px var(--rmdp-secondary-gray);
}

.gray .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-gray);
}

.gray .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-transparent-gray);
  color: black;
}

.gray .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-gray);
}

.gray .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-gray);
  box-shadow: 0 0 3px var(--rmdp-shadow-gray);
}

.gray .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-range-start-end) !important;
}

.gray .b-deselect {
  color: var(--rmdp-deselect-gray);
  background-color: white;
}

.gray .rmdp-action-button {
  color: var(--rmdp-primary-gray);
}

.gray .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-gray);
}

.gray .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-gray);
}
