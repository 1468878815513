.selecting .selectableItem {
  opacity: 0.5;
}

.selecting .selectableItem:hover {
  opacity: 1;
}

.selecting .selectableItem {
  opacity: 0.5;
}

.selectableItem:hover::before {
  content: '\00a0';
  height: 100%;
  left: -5000px;
  position: absolute;
  top: 0px;
  width: 10000px;
  z-index: -1;
  border: solid;
  border-width: 0.5px;
}

.selectableItem:hover::after {
  content: '\00a0';
  height: 10000px;
  left: 0px;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
  border: solid;
  border-width: 0.5px;
}
